/* Card Section */
.card {
  &-document {
    min-width: 327px;
    .card {
      &-body {
        .rounded-circle {
          width: 40px;
          height: 40px;
          border: 1px solid #d3d3d3;
        }
      }
      &-title {
        min-width: 220px;
        width: 220px;
      }
      &-footer {
        border: 0;
        background: transparent;
        .locked-by {
          min-width: 250px;
          width: 250px;
        }
        .badge {
          border: 1px solid #d3d3d3;
        }
      }
    }
    border-color: #e9e9e9;

    // &:hover {
    //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect on hover */
    // }
  }
  &.locked {
    // opacity: 80%;
    // background: $gray-100 !important;
    /* fallback for old browsers */
    background: #F3E5C1;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to bottom, #F3E5C1, #f9f9f9);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to bottom, #F3E5C1, #f9f9f9);
  }
  &.draft {
    /* fallback for old browsers */
    background: $blue-100;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to bottom, $blue-100, #e6ecf5);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to bottom, $blue-100, #e6ecf5);
  }
  &.staging {
    /* fallback for old browsers */
    background: $orange-200;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to bottom, $orange-200, $orange-100);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to bottom, $orange-200, $orange-100);
  }
  &.production {
    /* fallback for old browsers */
    background: $teal-100;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to bottom, $teal-100, $white);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to bottom, $teal-100, $white);
  }
  &.deleted {
    opacity: 80%;
    background: #960909 !important;
  }
}

/*new document model */

.model-context.new-document-model {
    .modal {
      &-dialog {
        min-width: 50%;
      }
      &-body {
        #dropdown-equipment {
          width: 100%;
          text-align: left;
        }
      }
    }
  }

