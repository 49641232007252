.nav-item {
  line-height: normal;
  a.nav-link {
    font-weight: 700;
    color: $secondary;
    border-bottom: transparent 2px solid;
    &:hover {
      border-bottom: $dark 2px solid;
      transition: border 0.3s ease-out;
      // transition-property: border;
      // transition-duration: .5s;
      // transition-timing-function: ease-out;
    }
    &.active {
      border-bottom: $dark 2px solid;
      color: $secondary;
      
    }
    
  }
}


// &::before {
//   content: "";
//   position: absolute;
//   z-index: -1;
//   left: 51%;
//   right: 51%;
//   bottom: -1.25rem;
//   background: #003b71;
//   height: 4px;
//   transition-property: left,right;
//   transition-duration: .3s;
//   transition-timing-function: ease-out;
// }