.flip-card {
  width: 300px;
  min-height: 200px;
  perspective: 1000px; /* Add some perspective */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  background-color: #bbb !important;
}

/* Style the front side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

/* Style the front side */
.flip-card-front {
  background-color: transparent;
  color: $secondary;
}

/* Style the back side */
.flip-card-back {
  background-color: transparent;
  color: $secondary;
  transform: rotateY(180deg);
}

/* Apply styles when flipped */
.flip-card-inner.flipped {
  transform: rotateY(180deg);
  background-color: #2980b9 !important;
}

.conf-property {
  min-height: 350px;
}

.conf-view {
  height: 150px;
}
