// .sidebar.authoring {
//   padding-bottom: 60px;
// }

/* Section sidebar*/
.sidebar {
  background: #f9f9f9;
  border-right: 1px solid #dddddd;
  direction: ltr;
  width: 20%;
  min-width: 350px;
  max-height: calc(100vh - 71.88px);
  padding-bottom: 100px;
  overflow: hidden;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out,
    margin-right 0.35s ease-in-out, right 0.35s ease-in-out;

  &-content {
    padding: 8px;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 172px);
    background-color: #f8f9fa;
  }
}

.authoring {
  &-header {
    padding: 0 15px;
    background-color: #eeeeee;
    color: $secondary;
    border-bottom: 1px solid #dddddd;

    &-row {
      padding: 11.5px 0;
    }

    .title-icon {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &-content {
    display: flex;
    position: relative;

    &.full {
      flex: 1 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .document {
      position: relative;

      &-content {
        flex: 1 1;
        height: 100%;
        height: calc(100vh - 146px);
        overflow-y: scroll;
        border-right: 1px solid #dddddd;
        padding: 18px;
        position: relative;

        // z-index: 10;
        // &::before {
        //   content: "";
        //   background-image: url("../images/household-appliances-icon-background-small.png");
        //   background-repeat: repeat;
        //   position: absolute;
        //   top: 0px;
        //   right: 0px;
        //   bottom: 0px;
        //   left: 0px;
        //   opacity: 0.35;
        // }
        .treeview {
          z-index: 50;

          .node {
            &.active {
              background-color: aqua;
            }
          }

          .node-toggle {
            cursor: pointer;
            display: flex;
            text-decoration: none;
            color: $dark;
            font-weight: 500;
            padding: 3px 5px;

            .text {
              &-desc {

                // padding: 3px 5px 3px 30px;
                p {
                  margin: 0;
                }
              }
            }

            &.question {
              color: $question;
            }

            &.solution {
              color: $success;
            }

            &.active {
              color: $white;
              background-color: #137cbd;

              &.question {
                color: $white;
                background: $question;

                svg {
                  fill: $white;
                }
              }

              &.solution {
                color: $white;
                background: $success;

                svg {
                  fill: $white;
                }
              }

              &.option {
                svg {
                  fill: $white;
                }
              }
            }

            .question,
            .solution {
              &-red {
                fill: $danger !important;
              }

              &-blue {
                fill: $primary !important;
              }
            }
          }
        }
      }

      &-property {
        min-width: 300px;
        max-width: 300px;
        height: 100%;
        background: #f8f9fa;
      }
    }
  }

  &-filter {
    background-color: #eeeeee;
    border-bottom: 1px solid #dddddd;

    .dropdown-toggle::after {
      display: none;
    }

    &-header {
      &.active {
        .input-group-text {
          background-color: antiquewhite;
        }

        background-color: antiquewhite;
        // .dropdown-toggle {
        //   display: none;
        // }
      }
    }
  }
}

.tree-search {
  flex: 14 !important;
  border-color: #0e2d44;
}