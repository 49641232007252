.model-context {
  .modal {
    &-dialog {
      min-width: 40%;
    }
    &-header {
      /* fallback for old browsers */
      background: #003b71;

      /* Chrome 10-25, Safari 5.1-6 */
      background: -webkit-linear-gradient(
        to right,
        rgba(0, 59, 113, 1),
        rgb(62, 144, 221)
      );

      /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      background: linear-gradient(
        to right,
        rgba(0, 59, 113, 1),
        rgb(62, 144, 221)
      );
      color: white;
    }
    &-body {
      /* fallback for old browsers */
      background: #f6f6f6;
      min-height: 100px;
    }
    &-footer {
      /* fallback for old browsers */
      background: #f6f6f6;

      /* Chrome 10-25, Safari 5.1-6 */
      background: -webkit-linear-gradient(
        to top,
        rgb(243, 243, 243),
        rgb(255, 255, 255)
      );

      /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      background: linear-gradient(
        to top,
        rgb(243, 243, 243),
        rgb(255, 255, 255)
      );
    }
  }
}
