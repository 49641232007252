.deployment-card {
  min-height: 98px;

  .deployment {
    position: relative;

    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}

.properties-tabs {

  .nav-tabs {
    border: 0 !important;
  }

  .nav-item {
    border-bottom: 0;

    .nav-link {
      border: 0;
      color: $primary;

      &:hover {
        border: 0;
        border-bottom: 3px solid $secondary;
      }

      &.active {
        color: $secondary;
        font-weight: 600;
        background: transparent;
        border-bottom: 3px solid $secondary;
      }
    }
  }



}