$primary: #0e2d44 !default;
$secondary: #12212e !default; // Action color
$success: #218142 !default;
$info: #ff7d50 !default;
$warning: #ffc01f !default;
$danger: #c54b25 !default;
$light: #f7f1e7 !default;
$dark: #3e5769 !default;
$body-color: #495057 !default;
$document-lock: rgb(209, 135, 0);
$question: #a36a00 !default;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'body-color': $body-color,
  'document-lock': $document-lock,
  'question': $question,
);

@import url('//fonts.googleapis.com/css?family=Roboto');
@import '/node_modules/bootstrap/scss/bootstrap.scss';
@import 'utils';
@import 'app-nav';
@import 'documents';
@import 'modal';
@import 'authoring';
@import 'flip-card';
@import 'deployment';

$theme-colors: map-merge($theme-colors, $theme-colors);

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $light;
}

.wrapper {
  align-items: stretch;
  display: flex;
  width: 100%;
  min-height: calc(100vh - 72.88px);
  background-color: rgba(155, 255, 255, 0.08); // rgba(234, 248, 248, 0.08); // rgba(18, 130, 235, 0.08);
  background-image: url('../images/household-appliances-icon-background-small.png');
  background-repeat: repeat;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 73px);
  min-width: 0;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out,
    margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
  width: 100%;
}

@media (max-width: 767.98px) {
  .main {
    overflow-y: hidden;
  }
}

// Generate classes for all colors
@each $color, $value in $theme-colors {
  .svg-#{$color} {
    svg {
      fill: $value;
    }
  }
}

@each $color, $value in $theme-colors {
  .ge-text-#{$color} {
    color: $value;
  }

  .ge-bg-#{$color} {
    background-color: $value;
  }
}

/* Scrollbard thin */

/* For WebKit based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px;
  /* Set the width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Set the background color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  /* Set the color of the scrollbar handle */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Set the color of the scrollbar handle on hover */
}

.card {
  border-color: #f1f1f1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  &-header {
    // min-height: 100px;
    // color: #f1f1f1;
    background-image: url('../images/ge-logo.png');
    /* Replace 'your-image-url.jpg' with your image URL */
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    /* Use auto to maintain the original image size */
  }

  & .view.gradient-card-header {
    padding: 1rem 1rem;
    text-align: center;
  }

  & h3,
  &.card-cascade h4 {
    margin-bottom: 0px;
  }

  & .admin-up {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -20px;

    & .fa {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      padding: 1.7rem;
      font-size: 2rem;
      color: #fff;
      text-align: left;
      margin-right: 1rem;
      border-radius: 3px;
    }

    & .data {
      float: right;
      margin-top: 2rem;
      text-align: right;
      min-width: 250px;

      & p {
        color: #999999;
        font-size: 12px;
      }
    }
  }
}

.bg-locked {
  background-color: transparent !important;
  opacity: 90%;

  input,
  checbox,
  textarea,
  .tox-editor-container,
  .badge {
    cursor: not-allowed !important;
  }
}

/* move to dashboard later */

.badge.position-relative.count {
  // background: #ded1b1;
  color: $secondary;
  font-weight: 500 !important;
  opacity: 80%;
  border: 1.5px solid #3e5769;
  &:hover {
    color: $secondary;
    background-color: #f8f9fa;
  }

  &.active {
    opacity: 100%;
    background: $secondary;

    .btn-link {
      color: white;
    }
  }

  span {
    position: absolute;
    top: -14px;
    right: -5px;
    padding: 5px;
    font-size: smaller;
    border-radius: 50%;
    height: 20px;
    width: 20px;

    &.tree {
      right: 15px;
    }
  }

  .btn-link {
    color: $dark;
    text-decoration: none !important;
  }
}

.wrapper.filtered {
  background-color: rgba(0, 26, 96, 0.05);
}

.btn-ico {
  width: 32px;
  max-width: 32px;
  padding: 0;
}

// search text
.text {
  &-desc {
    p {
      margin: 0;
    }
  }
}

.rsw-editor .rsw-ce{
  background: white !important;
}

// .node-toggle[aria-expanded="true"] {
//   background-color: yellow; /* Change this to the desired highlight color */
// }

.node-toggle[aria-expanded="true"] {
  // background-color: #e0f7fa; /* Light cyan background for expanded nodes */
  border-left: 5px solid #00796b; /* Dark teal border to highlight the expanded node */
}

.node-toggle {
  transition: background-color 0.3s ease, border-left 0.3s ease;
}

.node-toggle:hover {
  background-color: #b2ebf2; /* Slightly darker cyan on hover */
}